<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section v-if="user.is_super_user == '1'">
        <q-select
          outlined
          v-model="selMusyrif"
          label="Musyrif"
          :options="musyrif"
          @input="getSiswaList"
        />
      </q-card-section>
      <q-card-section class="q-gutter-md">
        <q-btn
          flat
          label="back"
          color="primary"
          @click="$router.go(-1)"
        ></q-btn>
        <q-input outlined readonly v-model="date">
          <template v-slot:prepend>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy transition-show="scale" transition-hide="scale">
                <q-date v-model="date" mask="YYYY-MM-DD">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input outlined label="Cari Siswa" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        wrap-cells
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="height:calc(100vh - 335px)"
      >
        <thead>
          <tr>
            <th>No</th>
            <th>Nama</th>
            <th>
              <q-checkbox v-model="checkAll" @input="checkingAll"></q-checkbox>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filSiswa" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.label }}</td>
            <td><q-checkbox v-model="val.checked"></q-checkbox></td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions class="q-pa-none" align="stretch">
        <q-btn
          unelevated
          class="full-width no-border-radius"
          color="negative"
          label="simpan"
          @click="simpan"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      siswa: [],
      searchTerm: "",

      user: {},

      musyrif: [],
      selMusyrif: {},

      checkAll: true,
    };
  },
  async mounted() {
    await this.getUser();
    if (this.user.is_super_user == "1") {
      await this.getMusyrif();
    } else {
      this.selMusyrif.value = this.user.id;
    }
    await this.getSiswaList();
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter((val) => {
          let cNama =
            val.label.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !=
            -1;
          return cNama;
        });
      }
    },
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/penilaian/absensi/getmusyrif`);
      this.musyrif = resp.data;
      if (resp.data.length > 0) {
        this.selMusyrif = resp.data[0];
      }
      this.$q.loading.hide();
    },
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    checkingAll() {
      if (this.checkAll) {
        for (let item of this.siswa) {
          item.checked = true;
        }
      } else {
        for (let item of this.siswa) {
          item.checked = false;
        }
      }
    },
    async getSiswaList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/tahfidz/getsiswa/${this.selMusyrif.value}`
      );
      for (let item of resp.data) {
        item.checked = true;
      }
      this.siswa = resp.data;
      this.$q.loading.hide();
    },
    async simpan() {
      let data = [];
      for (let item of this.siswa) {
        if (item.checked) {
          data.push(item.value);
        }
      }
      await this.$http.post(
        `/penilaian/tahfidz/absenmasal/${localStorage.getItem(
          "id_tahun_ajaran"
        )}/${this.date}`,
        data
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
